import React from "react"
import SEO from "../../components/seo"
import Grid from "@material-ui/core/Grid"
import servicesStyles from "./services.module.scss"
import { Link } from 'gatsby';
import servicios from "../../images/servicios.jpg"

const Services = () => (
  <Grid container className={servicesStyles.servicesContainer}>
    <SEO
      title="Servicios"
      description="P&P Digital consulting ofrece una variedad de servicios enfocados en 
            posicionar a su empresa en el liderazgo digital en su industria"
      image={`https://www.ppdc.mx${servicios}`}
      url="/services"
    />
    <Grid
      item
      xs={12}
      sm={4}
      className={[servicesStyles.serviceContainer, servicesStyles.design].join(
        " "
      )}
    >
      <Link
        title="diseño"
        className={servicesStyles.link}
        fade
        to="/services/design"
      >
        <p className={servicesStyles.title}>Diseño</p>
      </Link>
    </Grid>
    <Grid
      item
      xs={12}
      sm={4}
      className={[
        servicesStyles.serviceContainer,
        servicesStyles.marketing,
      ].join(" ")}
    >
      <Link
        title="marketing"
        className={servicesStyles.link}
        fade
        to="/services/marketing"
      >
        <p className={servicesStyles.title}>Marketing</p>
      </Link>
    </Grid>
    <Grid
      item
      xs={12}
      sm={4}
      className={[servicesStyles.serviceContainer, servicesStyles.dev].join(
        " "
      )}
    >
      <Link
        title="desarrollo"
        className={servicesStyles.link}
        fade
        to="/services/dev"
      >
        <p className={servicesStyles.title}>Desarrollo</p>
      </Link>
    </Grid>
  </Grid>
)

export default Services
